import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts/layout';

import { Slider } from '../components';

import rtEko from '../assets/partners/rt-eko.png';
import jablotron from '../assets/partners/jablotron.png';
import taphome from '../assets/partners/taphome.png';
import siemens from '../assets/partners/siemens.png';
import rymarov from '../assets/partners/rd-rymarov.png';

import image from '../assets/icons/air.svg';

const sliderData = [
  {
    title: 'Inteligentná domácnosť vo vašom smartfone',
  },
  {
    title: 'Zjednodušte si ovládanie domácnosti alebo kancelárie',
  },
  {
    title: 'Cenovo dostupné smarthome riešenie',
  },
];

// const icons1 = [
//   {
//     icon: 'electro',
//     title: 'Elektroinštalácie',
//   },
//   {
//     icon: 'security',
//     title: 'Zabezpečovacie systémy',
//   },
//   {
//     icon: 'camera',
//     title: 'Kamerové systémy',
//   },
// ];

// const icons2 = [
//   {
//     icon: 'shading',
//     title: 'Tieniaca technika',
//   },
//   {
//     icon: 'heating',
//     title: 'Kúrenie',
//   },
//   {
//     icon: 'cold',
//     title: 'Chladenie',
//   },
//   {
//     icon: 'air',
//     title: 'Vzduchotechnika',
//   },
//   {
//     icon: 'lamp',
//     title: 'Osvetlenie',
//   },
// ];

// const icons3 = [
//   {
//     icon: 'sensorics',
//     title: 'Senzorika',
//   },
//   {
//     icon: 'automation',
//     title: 'Priemyselné dopravníky',
//   },
//   {
//     icon: 'thrust',
//     title: 'Pneumatické technológie',
//   },
//   {
//     icon: 'hydraulics',
//     title: 'Hydraulické technológie',
//   },
//   {
//     icon: 'plc',
//     title: 'Programovanie PLC',
//   },
//   {
//     icon: 'switch',
//     title: 'Stavba rozvádzačov',
//   },
//   {
//     icon: 'robotics',
//     title: 'Robotika',
//   },
//   {
//     icon: 'projecting',
//     title: 'Projektovanie a Revízie',
//   },
// ];

const partners = [
  {
    alt: 'RT Eko, s.r.o.',
    src: rtEko,
    href: 'http://vasevykurovanie.sk/',
  },
  {
    alt: 'Jablotron',
    src: jablotron,
    href: 'https://www.jablotron.com/sk/',
  },
  {
    alt: 'Taphome',
    src: taphome,
    href: 'https://taphome.com/sk/',
  },
  {
    alt: 'Siemens',
    src: siemens,
    href: 'https://www.siemens.com/sk/sk/home.html',
  },
  {
    alt: 'RD Rýmařov',
    src: rymarov,
    href: 'https://www.rdrymarov.sk/',
  },
];

const IndexPage = ({ data }) => {
  return (
    <Layout>
      {console.log(image.url)}
      <Slider
        slides={data.sliderImages.edges}
        slidesMobile={data.sliderImagesMobile.edges}
        content={sliderData}
      />
      <section className="box">
        <div className="container">
          <div className="row mb-24">
            <div className="col-12 services" data-aos="zoom-in">
              <h3
                className="h2 text-color-primary text-center"
                data-aos="fade-right"
              >
                Elektroinštalácie, zabezpečovacie a kamerové systémy
              </h3>
              <div className="row">
                <div className="col-sm-12">
                  <p className="services__description">
                    Kompletné poradenstvo, návrh, montáž aj revízie
                    elektroinštalácií, osvetlenia, bleskozvodov a rozvádzačov.
                    Riešime ako rodinné domy a bytovky, tak aj administratívne
                    či kancelárske budovy a priemyselné haly.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            {icons1.map((item, iconIndex) => (
              <div
                key={iconIndex}
                className="col-sm-6 col-md-3 offset-md-1 services__item"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
              >
                <Icon name={item.icon} className="mb-24" />
                <h4>{item.title}</h4>
              </div>
            ))}
          </div> */}
        </div>
      </section>
      <section className="box box--light" id="iod">
        <div className="container">
          <div className="row mb-24">
            <div className="col-12 services" data-aos="zoom-in">
              <h3
                className="h2 text-color-primary text-center"
                data-aos="fade-right"
              >
                Inteligentné ovládanie domácnosti
              </h3>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <p className="services__description">
                    Riešime ovládanie vašich domácností cez smartfón alebo
                    tablet. Pričom naďalej zostáva aj možnosť ovládania
                    zariadení prostredníctvom nástenných tlačidiel a ovládačov.
                    Našou špecializáciou je montáž a programovanie inteligentnej
                    domácnosti značky TapHome - absolútneho slovenského lídra v
                    tejto oblasti.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6">
                  <p className="services__description">
                    Inteligentná elektroinštalácia zjednoduší ovládanie vášho
                    domu, bytu alebo kancelárie. Dokáže riadiť kúrenie,
                    chladenie, žalúzie alebo svetlá. Tiež zariadenia na
                    rekuperáciu, tepelné čerpadlá, zavlažovanie, ovládanie
                    LED-pásov. Inteligentná elektroinštalácia dokáže merať
                    spotrebu domácnosti, riadiť solárne panely, brány a dvere.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            {icons2.map((item, iconIndex) => (
              <div
                key={iconIndex}
                className="col-sm-6 col-md-4 services__item"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
              >
                <Icon name={item.icon} className="mb-24" />
                <h4>{item.title}</h4>
              </div>
            ))}
          </div> */}
        </div>
      </section>

      {/* <section className="box" id="pa">
        <div className="container">
          <div className="row mb-24">
            <div className="col-12 services" data-aos="fade-left">
              <h3
                className="h2 text-color-primary text-center"
                data-aos="zoom-in"
              >
                Priemyselná automatizácia
              </h3>
              <p className="services__description">
                Mame dlhoročné skúsenosti s projektovaním, servisom a montážou
                automatizácie vo výrobných halách v Nemecku, Rakúsku a
                Slovensku. Na ovládanie výrobných procesov používame produkty
                spoločnosti Siemens ako napríklad Simatic S7-300 alebo v menších
                aplikáciách napríklad Siemens LOGO!
              </p>
            </div>
          </div>
          <div className="row">
            {icons3.map((item, iconIndex) => (
              <div
                key={iconIndex}
                className="col-sm-6 col-md-3 services__item services__item--light-hover"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="500"
              >
                <Icon name={item.icon} className="mb-24" />
                <h4>{item.title}</h4>
              </div>
            ))}
          </div> 
        </div>
      </section> */}

      <section className="box">
        <div className="container">
          <div className="row mb-24">
            <div className="col-sm-12 col-md-6 services" data-aos="fade-left">
              <h3 className="h3 text-color-primary" data-aos="zoom-in">
                Tradícia, dlhoročné skúsenosti a absolútna odbornosť
              </h3>
              <p className="services__description">
                Spoločnosť ISC Group, s.r.o. bola v roku 2017 založená ľuďmi so
                skúsenosťami v oblasti elektroinštalácií. Máme prax aj v oblasti
                priemyselnej automatizácie značky Siemens. Pracovali sme na
                projektoch vo VW Slovakia a.s. a pôsobíme nielen po celom
                Slovensku, ale aj v Česku, Rakúsku a v Nemecku.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 services" data-aos="fade-left">
              <h3 className="h3 text-color-primary" data-aos="zoom-in">
                Uprednostnujeme kvalitu pred kvantitou
              </h3>
              <p className="services__description">
                A to aj na úkor vyššej ceny. Našou filozofiou totiž je
                zabezpečiť 100 %-ne spoľahlivé a dlhodobo funkčné riešenie pre
                vás ako zákazníka.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="box box--light" id="partners">
        <div className="container">
          <div className="row mb-24">
            <div className="col-12 services">
              <h2 className="h2 text-color-primary text-center">PARTNERI</h2>
            </div>
          </div>
          <div className="row">
            {partners.map((partner, partnerIndex) => (
              <div
                key={partnerIndex}
                className="col-12 col-sm-6 col-md-3 col-lg-3 text-center"
              >
                <a
                  href={partner.href}
                  className="partners"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={partner.src} alt={partner.alt} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export function Head() {
  return (
    <>
      <title>
        Inteligentná domácnosť, elektorinštalácia, domové bytové alarmy | ICS
        Group, s.r.o.
      </title>
      <meta
        name="description"
        content="Ponúkame cenovo výhodné riešenia ovládania domácnosti za pomoci
        smartphonu alebo tabletu, pričom užívateľ má aj naďalej možnosť
        ovládania za pomoci nástenných tlačidiel a ovládačov."
      />
      <meta
        name="keywords"
        content="inteligentná domácnosť, elektorinštalácia, domové bytové alarmy,
        automatizácie, zabezpečovacie systémy, inteligentná domácnosť"
      />
    </>
  );
}

export const query = graphql`
  query IndexPage {
    sliderImages: allFile(
      filter: {
        absolutePath: { regex: "/slider/" }
        name: { regex: "/^((?!mobile).)*$/" }
      }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }

    sliderImagesMobile: allFile(
      filter: {
        absolutePath: { regex: "/slider/" }
        name: { regex: "/mobile/" }
      }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1016) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
